<template>
  <div>
    <machines-list-add-new
      :is-add-new-machine-sidebar-active.sync="isAddNewMachineSidebarActive"
      @refetch-data="fetchMachines"
    />
    <machines-list-edit
      :key="JSON.stringify(selectedMachine)"
      :is-edit-machine-sidebar-active.sync="isEditMachineSidebarActive"
      :machine.sync="selectedMachine"
      @refetch-data="fetchMachines"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("eachPage") }}</label>
            <v-select
              v-model="perPage"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $t("piece") }} </label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('search')"
              />
              <b-button
                variant="primary"
                @click="
                  isAddNewMachineSidebarActive = !isAddNewMachineSidebarActive
                "
              >
                <span class="text-nowrap">{{ $t("newMachine") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refMachineListTable"
        class="position-relative"
        :items="filteredMachines"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(delete)="data">
          <b-link @click="deleteMachine(data.item.id)">
            <div class="d-flex">
              <feather-icon icon="TrashIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("delete")
              }}</small>
            </div>
          </b-link>
        </template>

        <template #cell(edit)="data">
          <b-link @click="openEditSideBar(data)">
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }} </span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalMachines"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useMachineList from "../../useMachineList";
import MachinesListAddNew from "./MachineListAddNew.vue";
import MachinesListEdit from "./MachineListEdit.vue";

import vSelect from "vue-select";

export default {
  components: {
    MachinesListAddNew,
    MachinesListEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,

    vSelect,
  },
  data() {
    return {};
  },

  setup({}) {
    const isAddNewMachineSidebarActive = ref(false);
    const isEditMachineSidebarActive = ref(false);
    const selectedMachine = ref({});
    const openEditSideBar = ({ item }) => {
      selectedMachine.value = item;
      isEditMachineSidebarActive.value = !isEditMachineSidebarActive.value;
    };
    const {
      fetchMachines,
      tableColumns,
      perPage,
      currentPage,
      totalMachines,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMachineListTable,
      refetchData,
      filteredMachines,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteMachine,
    } = useMachineList();
    fetchMachines();
    return {
      // Sidebar
      isAddNewMachineSidebarActive,
      isEditMachineSidebarActive,
      openEditSideBar,
      filteredMachines,
      selectedMachine,

      fetchMachines,
      tableColumns,
      perPage,
      currentPage,
      totalMachines,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMachineListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI

      // Extra Filters
      typeFilter,
      genderFilter,
      deleteMachine,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
