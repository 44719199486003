<template>
  <div><machine-list /></div>
</template>

<script>
import MachineList from "./components/MachineList/MachineList.vue";
export default {
  components: { MachineList },
};
</script>

<style></style>
