<template>
  <b-modal
    id="edit-machine-sidebar"
    centered
    :visible="isEditMachineSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    ok-only
    :title="$t('updateMachine')"
    @ok="onSubmit(blankMachineData)"
    @hidden="formValidation(resetblankmachine).resetForm"
    @change="(val) => changed(val)"
  >
    <!-- BODY -->

    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblankmachine
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <!-- machineid -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="machineid"
        >
          <b-form-group :label="$t('machineId')" label-for="machineid">
            <b-form-input
              id="machineid"
              v-model="blankMachineData.machineid"
              autofocus
              :state="
                formValidation(resetblankmachine).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
              disabled
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- Barn -->
        <validation-provider
          #default="validationContext"
          name="Barn"
          rules="required"
        >
          <b-form-group :label="$t('barn')" label-for="Barn">
            <v-select
              v-model="blankMachineData.barnid"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="barns"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => changeSelectedBarn(val)"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Padok -->
        <validation-provider
          v-if="blankMachineData.barnid"
          #default="validationContext"
          name="Padok"
          rules="required"
        >
          <b-form-group :label="$t('padok')" label-for="Padok">
            <v-select
              v-model="blankMachineData.padokid"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="padoks"
              class="w-100"
              :reduce="(val) => val.value"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- machinecode -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="machinecode"
        >
          <b-form-group :label="$t('machineCode')" label-for="machinecode">
            <b-form-input
              id="machinecode"
              v-model="blankMachineData.machinecode"
              autofocus
              :state="
                formValidation(resetblankmachine).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- defaulttour -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="defaulttour"
        >
          <b-form-group :label="$t('defaulttour')" label-for="defaulttour">
            <b-form-input
              id="defaulttour"
              v-model="blankMachineData.defaulttour"
              autofocus
              :state="
                formValidation(resetblankmachine).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
              type="number"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- onetouramount -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="onetouramount"
        >
          <b-form-group :label="$t('onetouramount')" label-for="onetouramount">
            <b-form-input
              id="onetouramount"
              v-model="blankMachineData.onetouramount"
              autofocus
              :state="
                formValidation(resetblankmachine).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
              type="number"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditMachineSidebarActive",
    event: "update:is-edit-machine-sidebar-active",
  },
  props: {
    isEditMachineSidebarActive: {
      type: Boolean,
      required: true,
    },
    machine: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankMachineData: { ...this.machine },
      formValidation: formValidation,
      padoks: [],
      barns: [],
      machineTypes: [],
      selectedBarn: null,
    };
  },
  async mounted() {
    let data = await this.$store.dispatch(
      "definitionsModule/fetchBarns",
      this.$store.state.app.selectedPlantId
    );
    data.map((x) =>
      this.barns.push({
        label: x.barnname,
        value: x.id,
        ...x,
      })
    );
    data = await this.$store.dispatch("machinesModule/fetchMachineTypes");
    data.map((x) =>
      this.machineTypes.push({ label: x.machinetype, value: x.id, ...x })
    );
    if (this.blankMachineData.barnid <= 0) {
      this.changeSelectedBarn(data.length != 0 ? data[0].id : 0);
    } else {
      this.changeSelectedBarn(this.blankMachineData.barnid);
    }
  },
  methods: {
    async changeSelectedBarn(barnid) {
      this.selectedBarn = null;
      const selected =
        this.barns.filter((x) => x.id === barnid)[0] != undefined
          ? this.barns.filter((x) => x.id === barnid)[0]
          : { barn: "", id: 0 };

      const data = await this.$store.dispatch("definitionsModule/fetchPadoks", {
        plantid: this.$store.state.app.selectedPlantId,
        barnid: selected.id,
      });
      this.padoks = [];
      data.map((x) =>
        this.padoks.push({
          label: x.padokname,
          value: x.id,
          ...x,
        })
      );
      this.blankMachineData.padokid;
      for (let index = 0; index < this.padoks.length; index++) {
        const element = this.padoks[index];

        if (element.id === this.blankMachineData.padokid) {
          this.blankMachineData.padokid = element.id;
          break;
        }
      }
      if (
        this.padoks.findIndex((x) => x.id == this.blankMachineData.padokid) ==
          -1 &&
        this.padoks.length > 0
      ) {
        this.blankMachineData.padokid = this.padoks[0].id;
      }
      this.blankMachineData.barnid = barnid;
    },
    async changed(val) {
      this.imgStr = "";
      this.resetblankmachine();

      this.$emit("update:is-edit-machine-sidebar-active", val);
    },
    onSubmit(blankMachineData) {
      store
        .dispatch("machinesModule/updateMachine", blankMachineData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-machine-sidebar-active", false);
        });
    },
    async resetblankmachine() {
      this.blankMachineData = { ...this.machine };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-machine-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
